<template>
    <div class="main">
        <div class="kefan">
            <div class="top" >
                <div class="tabs" v-bind:class="{ 'choosed': item.selected }" v-for="item in tabs" :key="item.value"
                    @click="changetab(item.value)">
                    {{ item.name }}
                </div>
            </div>
            <!-- 下拉 -->
            <div class="top" v-if="tab!== 3">
                <el-select v-model="value" placeholder="请选择" @change="changevalue">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">

                    </el-option>
                </el-select>

                <el-date-picker v-model="date" type="date" @change="changedate" value-format="yyyy-MM-dd"
                    placeholder="选择日期">

                </el-date-picker>
            </div>
           <div class="top" v-else>
             <el-button type="success" @click="changeVisible">新增活动</el-button>
           </div>

            <!-- 表格 -->
            <div class="table">
                <el-table :data="tableData" style="width: 100%">
                    <el-table-column :prop="item.prop" :label="item.label" v-for="item in label" :key="item.prop"></el-table-column>
                </el-table>
            </div>

            <!-- 页码 -->
            <div class="page" v-if="tab<3">
                <el-pagination
                background
                @current-change="handleCurrentChange"
                :current-page="page"
                layout="prev, pager, next"
                :total="total">
                </el-pagination>
            </div>
            <div class="page" v-else>
              <el-pagination
              background
              @current-change="handleActivityChange"
              :current-page="page"
              layout="prev, pager, next"
              :total="total">
              </el-pagination>
            </div>
        </div>
      <el-dialog title="新增活动" :visible.sync="visible" width="50%" @close="cancelDialog">
          <div class="dd">
            <div class="left">
              <el-image :src="imglist[0].url" style="width:150px;height: 150px;"></el-image>
              <el-date-picker
                  v-model="value1"
                  @change="changevalue1"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </div>
            <div class="right">
              <el-image :src="imglist[1].url" style="width:150px;height: 150px;"></el-image>
              <el-date-picker
                  v-model="value2"
                  @change="changevalue2"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </div>
          </div>
          <el-divider></el-divider>

          <div class="part">
            <p class="bold">预约开启时间</p>
            <el-date-picker
                v-model="value3"
                @change="changevalue3"
                type="datetime"
                value-format="timestamp"
                placeholder="选择日期时间">
            </el-date-picker>
          </div>
        <div class="center">
          <el-button type="danger" @click="visible = false">取消</el-button>
          <el-button type="primary" @click="submit">确定</el-button>
        </div>
      </el-dialog>
    </div>


</template>
<script>
import config from '../../config'
let api = config.API
export default {
    data() {
        return {
            tabs:
                [
                    { name: '会议室预约信息', value: 0, selected: true },
                    { name: '车备箱预约信息', value: 1, selected: false },
                    { name: '花架预约信息', value: 2, selected: false },
                    { name: '车备箱报名',value: 3, selected: false }
                ],
            imglist:[
              {url:this.$cbximg+'cbxq.jpg',name:'车备箱报名'},
              {url:this.$cbximg+'zhq.jpg',name:'综合区报名'},
            ],
            tab: 0,
            loading: false,
            options: [],
            value: 0,
            date: "",
            page: 1,
            pagesize: 10,
            total: 0,
            tableData: [],
            label: [],
            visible: false,
            value1: '',
            value2: '',
            value3: '',
            activity:[]
        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {
        this.init().then(() => {
            this.loadoptions();
            this.loaddata();
        });
    },
    methods: {
        // changevalue1
        changevalue1(e){
          this.value1 = e;
          this.obj1 = {
            cid:0,
            start:e[0],
            end:e[1],
            price:48,
          }
        },
        // changevalue2
        changevalue2(e){
          this.value2 = e
          this.obj2 = {
            cid:1,
            start:e[0],
            end:e[1],
            price:100,
          }
        },
        // changevalue3
        changevalue3(e){
          //转换为秒级时间戳
          this.value3 = e;
          this.schedule_time = Number(e/1000);
        },
        cancelDialog(){
          this.visible = false;
          this.value1 = '';
          this.value2 = '';
          this.value3 = '';
          this.obj1 = null;
          this.obj2 = null;
          this.schedule_time = null;
        },
        // 提交
        submit(){
          let load = this.loading
          if(load){
            return;
          }
          if((this.obj1 || this.obj2 ) && this.value3 === ''){
            this.$message.error("请选择活动时间")
            return;
          }
          this.activity = [this.obj1,this.obj2]
          this.loading = true;
          let loading = this.$loading({
            lock: true,
            text: '加载中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          this.$post(api.cbx+"add_schedule",{activity:this.activity,schedule_time:this.schedule_time}).then(res => {
            if (res.data.code === 200) {
              this.$message.success("提交成功");
              this.visible = false;
              this.loadactivity();
              } else {
              this.$message.error(res.data.msg);
              }
          }).then(() => {
            this.loading = false;
            loading.close();
          })
        },
        // 切换标签栏
        changetab(e) {
            this.tabs.forEach(item => {
                item.selected = false;
                if (item.value == e) {
                    item.selected = true;
                }
            });
            this.tab = e;
            this.value = 0;
            this.page = 1;
            if(this.tab === 3){
              this.loadactivity();
            }else{
              this.loadoptions();
              this.loaddata();
            }

        },
        // 选择下拉框
        changevalue(e) {
            this.value = e;
            this.page = 1;
            this.loaddata();
        },
        // 更换日期
        changedate(e) {
            this.date = e;
            this.page = 1;
            console.log(e);
            this.loaddata();
        },
        // 页码切换
        handleCurrentChange(val) {
        this.page = val;
        this.loaddata()
        },
        // 页码切换2
        handleActivityChange(val) {
          this.page = val;
          this.loadactivity()
        },
        // 打开对话框
        changeVisible() {
          this.visible = true;
        },
        // 初始化数据
        init() {
            return new Promise((resolve, reject) => {
                try {
                    // 初始化日期对象，默认为今天的0点
                    var date = new Date();
                    date.setHours(0, 0, 0, 0);
                    // 将日期格式化成yyyy-MM-dd
                    this.date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
                    resolve(); // 成功时调用 resolve
                } catch (error) {
                    reject(error); // 失败时调用 reject
                }
            });
        },
        // 加载options
        loadoptions() {
            this.$post(api.list, { do: "tablist", tab: this.tab }).then(res => {
                if (res.data.code == 200) {
                    this.options = res.data.data;
                } else {
                    this.$message.error(res.data.msg);
                }
            })
                .catch(err => {
                    this.$message.error(err);
                }
                );
        },

        // 加载表格数据
        loaddata() {
            let load = this.date.loading
            if (load) {
                return;
            }
            this.loading = true;
            let loading = this.$loading({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.$post(api.list, { do: "reserve", tab: this.tab, value: this.value, date: this.date, page: this.page, pagesize: this.pagesize }).then(res => {
                if (res.data.code === 200) {
                    this.tableData = res.data.data.data;
                    this.total = res.data.data.total;
                    this.label = res.data.data.label;
                } else {
                    this.$message.error(res.data.msg);
                }
            }).then(() => {
                this.loading = false;
                loading.close();
            })
        },
        // 加载活动信息
        loadactivity(){
          let load = this.loading
          if(load){
            return;
          }
          this.loading = true;
          let loading = this.$loading({
            lock: true,
            text: '加载中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          this.$get(api.cbx+"activelist",{page:this.page,pagesize:this.pagesize}).then(res => {
            if (res.data.code === 200) {
              this.tableData = res.data.data.data;
              this.total = res.data.data.total;
              this.label = res.data.data.label;
            } else {
              this.$message.error(res.data.msg);
            }
          }).then(() => {
            this.loading = false;
            loading.close();
          })
        },
    }
};
</script>
<style scoped>
.main {
    width: 100%;
}

.top {
    width: 100%;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.page{
    width: 90%;
    margin: 20px auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.tabs {
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
}

.tabs:hover {
    background-color: #52C290;
    color: #fff;
}

.choosed {
    background-color: #52C290;
    color: #fff;
}
.dd{
  width: 100%;
  display: flex;
}
.left{
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;

}
.right{
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.table {
    width: 100%;
    margin-top: 20px;
}
.table .el-table__body-wrapper{
  max-height: 400px;
  overflow: auto;
}
.part{
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.center{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
</style>